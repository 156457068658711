.container {
  /* padding: 16px 0; */
  /* margin-top: 16px; */
  display: flex;
  flex-direction: column;
  height: 100%;
}

.server_rating {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background-color: white;

}

.header_left {
  display: flex;
  align-items: center;
}

.header_left span {
  margin-right: 14px;
}

.textarea_box {
  padding: 16px;
  display: flex;
  margin-top: 16px;
  background-color: white;
  border-radius: 10px;
}

.textarea_input {
  height: 118px;
  border: 0px solid transparent;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #333333;
}

.textarea_input::-webkit-input-placeholder {
  color: #999999;
}

.main_container {
  background: radial-gradient(29.57% 29.19% at 7.2% -5.58%, #DDFFF5 0%, #FFFFFF 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;
  border-radius: 16px;
  margin-top: 16px;
  padding: 16px 12px 4px 12px;
}

.main_title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}

.list_content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #eeeeee;

}

.list_content:last-child {
  border-bottom: 0;
}

.list_content_left {
  display: flex;
  align-items: center;
  position: relative;
}

.list_content_tags {
  position: absolute;
  bottom: -5%;
  left: -3%;
  padding: 0 2px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #FFFFFF;
  border-radius: 2px;
}

.list_content_info {
  margin-left: 12px;
}

.list_content_info p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #333333;

}

.list_content_info span {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  margin-top: 8px;
}

.avatar_img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
}

.leader_img {
  width: 44px;
  height: 20px;
}

.abc {
  margin-top: 8px;
}

.orange {
  background-color: #ec632a;
}

.green {
  background-color: #3ed291;
}

.blue {
  background-color: #00b7ee;
}

.internship {
  padding: 0 4px;
  background-color: #5b5b5b;
  border-radius: 2px;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  color: #FFFFFF;
  margin-top: 8px;
}

.line {
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
  margin-top: 12px;
}

.order_info {
  padding: 12px 0;
}

.order_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order_info_money {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #F64D41;
}

.order_info_content {
  display: flex;
  justify-content: space-between;
  align-items: start;
  margin-top: 16px;
}

.order_info_content:nth-child(1) {
  margin-top: 0;
}

.order_info_content p {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  min-width: 20%;
}

.order_info_content span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: right;
  color: #333333;
}

.footer {
  margin-top: 16px;
  background: #32B97A;
  text-align: center;
  padding: 11px 0;
  margin-bottom: 16px;
  border-radius: 8px;
}

.footer span {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: #FFFFFF;
}

.main {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 100%;
  overflow: auto;
  margin-top: 16px;
}

.main::-webkit-scrollbar {
  display: none;
}


.rating_container {
  margin-top: 16px;
}

.customer_service {
  background: radial-gradient(29.57% 29.19% at 7.2% -5.58%, #DDFFF5 0%, #FFFFFF 100%)
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
  ;
  border-radius: 16px;
  margin-top: 16px;
  padding: 16px 12px;
}

.qrcode_service {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #333333;
}

.qrcode_service1 {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  margin-top: 10px;
}

.qrcode_box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}

.qrcode_box p {
  border: 1px solid #32B97A;
  border-radius: 12px;
  padding: 10px;
}

.qrcode_box p img {
  width: 158px;
  height: 158px;
}

.paymend_container {
  padding: 16px;
  background-color: white;
  border-radius: 16px;
  margin-top: 16px;
}

.paymend_title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  text-align: center;
}

.paymend_money {
  font-style: normal;
  font-weight: 700;
  font-size: 34px;
  color: #333333;
  text-align: center;
  margin-top: 16px;
}

.paymend_line {
  height: 1px;
  background-color: #eeeeee;
  margin-top: 16px;
}

.paymend_description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.textarea_box {
  display: flex;
  flex-direction: column;
}

.rating_image_box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.rating_image_box img {
  width: 100px;
  height: 100px;
}

.is_weixin {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #000000;
  opacity: 0.7;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #FFFFFF;
}

.is_weixin p {
  position: absolute;
  top: 15%;
}

.is_weixin img {
  position: absolute;
  top: 0;
  right: 1%;
}